import { Visitor } from './types'

import GalenJosephHunter from './galen_joseph_hunter'
import ShannonMattern from './shannon_mattern'
import JayNeedham from './jay_needham'
import Coraline from './coraline_ada_ehmke'
import Angela from './angela_aguayo'
import Luis from './luis_macias'

import LaurenLeeMcCarthy from './lauren_lee_mccarthy'
import CarolSabbadini from './carol_sabbadini'
import RickPrelinger from './rick_prelinger'
import AndresBurbano from './andres_burbano'
import ChipThomas from './chip_thomas'
import BlkSonicMvmnt from './blk_sonic_mvmnt'
import ColinIves from './colin_ives'
import AlbertoNovello from './alberto_novello'

import ElisabethSchimana from './elisabeth_schimana'
import StevenFeld from './steven_feld'
import MariaChavez from './maria_chavez'
import AnnaFriz from './anna_friz'
import GarnetHertz from './garnet_hertz'
import DesertArtLAB from './desertArtLAB'
import AmyLauren from './amy_lauren'
import MichaelMarder from './michael_marder'
import ErikaBalsom from './erika_balsom'
import JoanBrandi from './joan_brandi'
import HolgerSchulze from './holger_schulze'

import JussiParikka from './jussi_parikka'
import MinkiStoyanova from './minka_stoyanova'
import KhalikAllah from './khalik_allah'
import DouglasPaulson from './doug_paulson'
import HasanElahi from './hasan_elahi'
import Maryam from './maryam_muliaee'

export const visitors2024: Record<string, Visitor> = {
  [GalenJosephHunter.slug]: GalenJosephHunter,
  [ShannonMattern.slug]: ShannonMattern,
  [JayNeedham.slug]: JayNeedham,
  [Coraline.slug]: Coraline,
  [Angela.slug]: Angela,
  [Luis.slug]: Luis
}


export const visitors2023: Record<string, Visitor> = {
  [CarolSabbadini.slug]: CarolSabbadini,
  [LaurenLeeMcCarthy.slug]: LaurenLeeMcCarthy,
  [RickPrelinger.slug]: RickPrelinger,
  [AndresBurbano.slug]: AndresBurbano,
  [ChipThomas.slug]: ChipThomas,
  [BlkSonicMvmnt.slug]: BlkSonicMvmnt,
  [ColinIves.slug]: ColinIves,
  [AlbertoNovello.slug]: AlbertoNovello,
}


export const visitors2022: Record<string, Visitor> = {
  [ElisabethSchimana.slug]: ElisabethSchimana,
  [StevenFeld.slug]: StevenFeld,
  [MariaChavez.slug]: MariaChavez,
  [DesertArtLAB.slug]: DesertArtLAB,
  [MichaelMarder.slug]: MichaelMarder,
  [AmyLauren.slug]: AmyLauren,
  [ErikaBalsom.slug]: ErikaBalsom,
  [JoanBrandi.slug]: JoanBrandi,
  [HolgerSchulze.slug]: HolgerSchulze,
}

export const visitors2021: Record<string, Visitor> = {
  [Maryam.slug]: Maryam,
  [HasanElahi.slug]: HasanElahi,
  [DouglasPaulson.slug]: DouglasPaulson,
  [KhalikAllah.slug]: KhalikAllah,
  [GarnetHertz.slug]: GarnetHertz,
  [AnnaFriz.slug]: AnnaFriz,
  [MinkiStoyanova.slug]: MinkiStoyanova,
  [JussiParikka.slug]: JussiParikka,
}

export const allSlugs = Object.keys(visitors2021)
  .concat(
    Object.keys(visitors2022)
  )
  .concat(
    Object.keys(visitors2023)
  )
  .concat(
    Object.keys(visitors2024)
  )


export const getVisitorBySlug = (slug: string) =>
  visitors2024[slug] || visitors2023[slug] || visitors2022[slug] || visitors2021[slug]
