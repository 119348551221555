import Link from 'next/link'
import { Visitor, Locations } from './types'
import Profile from '../assets/media/luis_macias/profile.jpg'

const Luis: Visitor = {
  name: 'Luis Macias',
  logos: ['cmci', 'cdem', 'mal', 'mediastudies', 'cine'],
  slug: 'luis-macias',
  urls: ['https://luismacias.es', 'https://crater-lab.org/home/'],
  profile: {
    type: 'image',
    src: Profile,
    position: '93%',
    alt: 'Luis Macias',
    description:
      'white man standing in the dark behind four image projectors stacked in rows of two, each eminating different colors.'
  },
  bio: "Luis Macías is a media artist, filmmaker, and moving-image composer whose work delves into the formal and spectral properties of the moving image. He seamlessly integrates his artistic endeavors with specialized pedagogy in cinematic practices , where he rigorously explores the cinematographic device and the photochemical nature of various media. Emphasizing experimental and procedural methodologies, his projects utilize formats such as Super 8, 16mm, and 35mm film, as well as video, crafted for projection, performance, and installation. Macías's films and expanded media pieces have been featured in prestigious film festivals, art exhibitions, and music events globally, including the Oslo Cinemateket, the Cineteca Nacional de Mexico, Cineteca de Madrid, Los Angeles Filmforum, The Cinémathèque québécoise, IFFR Rotterdam, Oberhausen Film Festival, Punto de Vista, 25FPS,  Ann Arbor International Film Festival, MIDBO Bogotá, among many others. He has participated in collective exhibitions where his efforts span a diverse range collaborations with artists, musicians, and filmmakers, contributing to innovative collective works. As a co-founder and active member of CRATER-Lab, an independent artist-run film laboratory dedicated to analog practices in the arts, Macías  fosters a community of innovative craftmanship in curation, filmmaking and expanded media arts. In addition to his artistic practice, he has led theoretico-practical workshops focusing on various aspects of experimental media at art centers, cinematheques, museums, and universities.",
  bioJSX: (
    <div className="flex flex-col gap-2">
      <div>Luis Macías is a media artist, filmmaker, and moving-image composer whose work delves into the formal and spectral properties of the moving image. He seamlessly integrates his artistic endeavors with specialized pedagogy in cinematic practices , where he rigorously explores the cinematographic device and the photochemical nature of various media. Emphasizing experimental and procedural methodologies, his projects utilize formats such as Super 8, 16mm, and 35mm film, as well as video, crafted for projection, performance, and installation.</div>
      <div>Macías's films and expanded media pieces have been featured in prestigious film festivals, art exhibitions, and music events globally, including the Oslo Cinemateket, the Cineteca Nacional de Mexico, Cineteca de Madrid, Los Angeles Filmforum, The Cinémathèque québécoise, IFFR Rotterdam, Oberhausen Film Festival, Punto de Vista, 25FPS,  Ann Arbor International Film Festival, MIDBO Bogotá, among many others. He has participated in collective exhibitions where his efforts span a diverse range collaborations with artists, musicians, and filmmakers, contributing to innovative collective works.</div>
      <div>As a co-founder and active member of CRATER-Lab, an independent artist-run film laboratory dedicated to analog practices in the arts, Macías  fosters a community of innovative craftmanship in curation, filmmaking and expanded media arts. In addition to his artistic practice, he has led theoretico-practical workshops focusing on various aspects of experimental media at art centers, cinematheques, museums, and universities.</div>
    </div >
  ),
  events: [
    {
      kind: 'performance',
      title: 'Your Eyes are Spectral Machines',
      location: Locations.Atlas102,
      start: new Date("2025-03-10T17:00-06:00"),
      end: new Date("2025-03-10T19:00-06:00"),
      blurb: '"Your Eyes Are Spectral Machines" is a program of two filmic performances by Luis Macias that transforms cinema into a liminal act of transformation and creation. Each piece engages with the materiality of film through photochemical processes, mechanical manipulation, and projection-as-event. Process becomes structure, and closed forms open toward improvisation, dissolving boundaries between control and accident, visibility and disappearance.\n In "Spectral Landscape", modified projectors pulse with intermittent bursts of light, evoking delicate and violent impressions of nature as it emerges and dissolves, mutable and untamed. "The Eyes Empty and the Pupils Burning with Rage and Desire" begins with absence—an image devoured by its own destruction. From this rupture, a fragile, organic universe slowly takes form in a fevered exchange between emulsion and machine, where tension and fury collide in the luminous mechanics of projection. Macias’s performances uncover cinema as an ever-shifting terrain, a restless exploration of form and perception, where the ephemeral image of nature flickers into being and vanishes again.',
      blurbJSX: (<div className="flex flex-col gap-4">
        <div><b>Your Eyes Are Spectral Machines</b> is a program of two filmic performances by Luis Macias that transforms cinema into a liminal act of transformation and creation. Each piece engages with the materiality of film through photochemical processes, mechanical manipulation, and projection-as-event. Process becomes structure, and closed forms open toward improvisation, dissolving boundaries between control and accident, visibility and disappearance.</div>
        <div>In <b>Spectral Landscape</b>, modified projectors pulse with intermittent bursts of light, evoking delicate and violent impressions of nature as it emerges and dissolves, mutable and untamed. </div>
        <div><b>The Eyes Empty and the Pupils Burning with Rage and Desire</b> begins with absence—an image devoured by its own destruction. From this rupture, a fragile, organic universe slowly takes form in a fevered exchange between emulsion and machine, where tension and fury collide in the luminous mechanics of projection. Macias’s performances uncover cinema as an ever-shifting terrain, a restless exploration of form and perception, where the ephemeral image of nature flickers into being and vanishes again.</div>
      </div >),
      media: []
    }
  ],
}

export default Luis 
