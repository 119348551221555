
import { Visitor, Locations } from './types'
import Profile from '../assets/media/angela_aguayo/profile.jpg'
import Screen01 from '../assets/media/angela_aguayo/screenshot01.png'
import Screen02 from '../assets/media/angela_aguayo/screenshot02.png'
import Screen03 from '../assets/media/angela_aguayo/screenshot03.png'
import Screen04 from '../assets/media/angela_aguayo/screenshot04.png'
import Screen05 from '../assets/media/angela_aguayo/screenshot05.png'

const Angela: Visitor = {
  name: 'Angela J. Aguayo',
  logos: ['cmci', 'cdem', 'saslab'],
  slug: 'angela-aguayo',
  urls: ['https://www.ctvnetwork.org/', 'https://www.youtube.com/c/CommunityTVNetworkChicago'],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 30%',
    alt: 'Angela J. Aguayo',
    description:
      'white woman with dark black hair and a reddish stripe on the left side, wearing glasses and liptstick and necklace with a circle-over-cross pendant.'
  },
  bio: "Angela J. Aguayo is an Associate Professor of Media and Cinema Studies and a Faculty Fellow at the Humanities Research Institute at the University of Illinois, Urbana-Champaign. A scholar-media artist, Aguayo’s work explores the transformative power of documentary and participatory media to inspire cultural change and strengthen communities. Her interdisciplinary research bridges documentary studies, rhetoric, and critical media practices, with a focus on how storytelling can activate public participation and build collective agency. Her most recent book, Documentary Resistance: Social Change and Participatory Media (Oxford University Press, 2019), provides a critical framework for understanding the political impact of documentary film and its role in mobilizing social movements. As a director and producer, Aguayo’s documentary shorts have been featured in community engagement campaigns, exhibited at leading institutions such as the Anthology Film Archives, The Art Institute of Chicago, and Harvard University, and showcased at festivals worldwide. Aguayo is also the founding Director of the Illinois Community Media Project, which creates opportunities for underserved communities to develop media production skills and tell their own stories. She is currently working on her next book, Collective Matters: Public Engagement and Youth Media Production in the United States.",
  events: [
    {
      kind: 'talk',
      title: 'Community Engagement and Sustainable Storytelling',
      subtitle: 'Youth Media Production in the US',
      location: Locations.Chem142,
      start: new Date("2025-02-20T14:00-07:00"),
      end: new Date("2025-02-20T15:15-07:00"),
      blurb: 'In an era of growing expectations for public engagement, institutions—such as universities, government agencies, and corporations—are increasingly partnering with local organizations, including community groups, nonprofits, and grassroots movements. These collaborations aim to foster trust, address shared challenges, and leverage collective resources for lasting impact. Media Arts Practices play a key role in this evolving landscape, serving as a bridge for community storytelling. Youth-produced documentary emerges as a powerful space for public engagement, with young people acting as both creators and catalysts. By sharing their lived experiences, they challenge assumptions, amplify marginalized voices, and reimagine collective futures. However, engagement practices are fraught with ethical challenges. Power imbalances often result in top-down, extractivist approaches that undermine community agency. Addressing this requires a commitment to sustainable storytelling—practices that confront unsustainable systems, hold power accountable, and enable equitable, transformative futures. This lecture examines youth-led documentary production in the United States as a case study for sustainable storytelling. It explores how collaborative, bottom-up approaches to engagement can disrupt hierarchical traditions, foster shared knowledge production, and create meaningful, cross-cultural connections that empower communities.',
      media: [
        {
          type: 'image',
          src: Screen01,
          alt: '',
          description: 'Black boy looks into an old video camcorder in profile view.'
        },
        {
          type: 'image',
          src: Screen02,
          alt: '',
          description: 'White girl looks into an old video camcorder.'
        },
        {
          type: 'image',
          src: Screen03,
          alt: '',
          description: 'Old photo (likely 70s) of a group of mixed race youngsters and video equipment.'
        },
        {
          type: 'image',
          src: Screen04,
          alt: '',
          description: 'Black boy with dreads looks into a contemporary video camera with shotgun mic attached.'
        },
        {
          type: 'image',
          src: Screen05,
          alt: '',
          description: 'Old photo (likely 70s) of mixed race youth at a street corner. One is holding a video camera.'
        },


      ],
    },
  ],
}

export default Angela 
